<template>
    <div class="demo">
        <div class="title">木鱼 - Flutter
            <li>flutter 打包 android apk，记录配置签名的过程/调试的过程及flutter build apk.</li>
            blog地址：<a href="https://blog.csdn.net/daddykei/article/details/128172362">csdn</a>
            git地址：<a href="https://github.com/ThinkerWing/language/tree/feat/WoodenFish/woodenfish">github</a>
            <br/>
            <div style="margin-top: 10px;"></div>
        </div>
    </div>
</template>

<style scoped>
    .demo {
        display: flex;
        height: 100%;
        width: 100%;
        color: #333;
        text-shadow: none;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-size: 16px;
        text-align: left;
        margin: 10%;
    }
</style>
<script>
    export default {
        data() {
            return {
            }
        },
        created() {
        }
    }
</script>